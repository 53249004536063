import '~/public/static/css/eurostileLtStd.css'
import '~/public/static/css/normalize.css'
import '~/public/static/css/variables.css'
import '~/public/static/css/style.css'

import React, {useCallback, useEffect} from 'react'
import Head from 'next/head'
import Renderer from '~/components/Renderer'
import { useStore } from '~/store'
import { Provider } from 'react-redux'
import { getUser } from '~/store/actions/authentication'
import { ErrorBoundary } from 'react-error-boundary'
import {
  initGA,
  logPageView,
  logComponentView,
} from '~/helpers/google-analytics'
import ScreenProvider from '~/providers/ScreenProvider'
import {queryParams, storage} from '~/helpers'
import {isProduction} from "~/helpers/environment";
import {ClerkProvider} from '@clerk/clerk-react'
import {FallbackError} from "~/components/FallbackError";
import {Router, useRouter} from "next/router";
import {LAST_LOCATION} from "~/config/settings";
import {PostHogProvider} from "posthog-js/react";
import posthog from "posthog-js";

// Check that PostHog is client-side (used to handle Next.js SSR)
if (typeof window !== 'undefined' && process.env.NEXT_PUBLIC_POSTHOG_KEY) {
    posthog.init(process.env.NEXT_PUBLIC_POSTHOG_KEY, {
        api_host:
            process.env.NEXT_PUBLIC_POSTHOG_HOST || 'https://eu.i.posthog.com',
        person_profiles: 'identified_only',
        // Enable debug mode in development
        loaded: (posthog) => {
            if (process.env.NODE_ENV === 'development') posthog.debug()
        },
    })
}

function MyApp (props) {
  const store = useStore();
  const router = useRouter();

  useEffect(() => {
    if (isProduction) {
      initGA();
      logPageView();
      logComponentView('Component is _app.js');
    }

    if (typeof window !== 'undefined') {
      const params = queryParams()
      if (params.source === 'app' && params.token) {
        storage.setItem('token', params.token)
        store.dispatch(getUser())
      }
    }
  }, [])

    useEffect(() => {
        const handleRouteComplete = (route) => {
            window.segment?.page()
            // Remove basepath from url being saved
            const url = route.replace(new RegExp(`^${router.basePath}`), "")
            // Check to see if it's a valid url to save
            if(url.startsWith("/shardlibrary")) {
                localStorage.setItem(LAST_LOCATION, url)
            }
        }

        Router.events.on("routeChangeComplete", handleRouteComplete)

        return () => {
            Router.events.off("routeChangeComplete", handleRouteComplete)
        }
    }, [router])

    const routerReplace = useCallback((to) => {
        router.replace(to)
    }, [router])

    const routerPush = useCallback((to) => {
        router.push(to)
    }, [router])

    return (
        <>
            <Head>
                <title>GameGlass</title>
                <meta
                    name='viewport'
                    content='width=device-width,initial-scale=1,maximum-scale=1,user-scalable=no,minimal-ui'
                />
                <link
                    href='https://fonts.googleapis.com/css?family=Electrolize'
                    rel='stylesheet'
                />
                <link
                    href='https://fonts.googleapis.com/css?family=Barlow+Condensed:300,600'
                    rel='stylesheet'
                />
                <link
                    href='https://fonts.cdnfonts.com/css/proxima-nova-2'
                    rel='stylesheet'
                />
                <link
                    href='https://bucket.gameglass.gg/fonts/3A6108_0_0.woff2'
                    rel='binary/octet-stream'
                />
                <link
                    href='https://bucket.gameglass.gg/fonts/3A6108_0_0.eot'
                    rel='binary/octet-stream'
                />
                <link
                    href='https://bucket.gameglass.gg/fonts/3A6108_0_0.woff'
                    rel='application/font-woff'
                />
                <link
                    href='https://bucket.gameglass.gg/fonts/3A6108_0_0.ttf'
                    rel='binary/octet-stream'
                />
      </Head>
    <PostHogProvider client={posthog}>
      <ErrorBoundary
          fallbackRender={FallbackError}
          onReset={(details) => {
              localStorage.clear()
              sessionStorage.clear()
              location.reload()
          }}
      >

      <ClerkProvider routerReplace={routerReplace} routerPush={routerPush} publishableKey={process.env.NEXT_PUBLIC_CLERK_PUBLISHABLE_KEY}>
          <Provider store={store}>
            <ScreenProvider>
              <Renderer {...props} />
            </ScreenProvider>
          </Provider>
      </ClerkProvider>
      </ErrorBoundary>
        </PostHogProvider>
    </>
  )
}

export default MyApp
